@font-face {
  font-family: CeraR;
  src: url(CeraR.otf);
}
@font-face {
  font-family: CeraB;
  src: url(CeraB.otf);
}
@font-face {
  font-family: CeraM;
  src: url(CeraM.otf);
}
@font-face {
  font-family: CeraL;
  src: url(CeraL.otf);
}
